
@import url(node_modules/font-awesome/css/font-awesome.css);
/**
 * Note: this css file must be imported so that marker icons URLs will work
 * properly with react-leaflet. See https://github.com/PaulLeCam/react-leaflet/issues/453
 */
@import url(https://unpkg.com/leaflet@1.7.1/dist/leaflet.css);

@import url(node_modules/bootstrap/dist/css/bootstrap.min.css);
@import url(node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css);
@import url(node_modules/react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css);

@import url(node_modules/react-select/dist/react-select.css);

@import url(node_modules/react-virtualized/styles.css);
@import url(node_modules/react-virtualized-select/styles.css);
@import url(node_modules/rc-slider/assets/index.css);
@import url(node_modules/react-toggle/style.css);
@import url(node_modules/react-toastify/dist/ReactToastify.min.css);
@import url(node_modules/react-dropdown-tree-select/dist/styles.css);
/* 5t */
@import url(/node_modules/react-day-picker/lib/style.css);
/*@import url(node_modules/react-day-picker/dist/style.css);  serviva per la versione 8, ma non funge*/ 
@import url(node_modules/react-datetime/css/react-datetime.css);

@import url(lib/react-leaflet-fullscreen.css);
/* fine 5t */
@import url(lib/style.css);
